<template>
  <div class="login-container">
    <div class="login-header">
      <div class="icon">
        <img class="img" src="~@/assets/img/logo.png"/>
      </div>
      <div class="title-box">
        <label>{{ title }}</label>
      </div>
    </div>
    <div class="login-body">
      <div class="login-weaper animated bounceInDown">
        <div class="login-check" @click="check">
          <!--微信二维码登录 -->
          <img src="~@/assets/img/login-phone.png" v-if="loginType === 'qrcode'"/>
          <!-- 手机号登录-->
          <img src="~@/assets/img/login-qrcode.png" v-else-if="loginType==='userName'"/>
        </div>
        <div class="login-border">
          <div class="login-main" v-if="loginType === 'userName'">
            <h4 class="login-title">
              {{ loginTitle }}
            </h4>
            <div>
              <!-- 用户登录组件-->
              <userLogin
                  v-if="activeName==='userNameLogin' || activeName==='mobileCodeLogin'"
                  :activeName="activeName"
                  @activeChanged="changeActiveName($event)">
              </userLogin>
              <!-- 登录密码操作组件-->
              <changePassword
                  v-else-if="activeName==='pwd'"
                  @suc="suc"
                  @activeChanged="changeActiveName($event)">
              </changePassword>
            </div>
          </div>
          <div class="login-main" v-else style="position: relative">
            <div id="login_container" class="qrcode-box">
              <wxlogin
                  :appid = wxOpenKey()
                  :scope="'snsapi_login'"
                  :theme="'write'"
                  :redirect_uri='encodeURIComponent(wxOpenUrl())'
                  rel="external nofollow">
              </wxlogin>
            </div>
            <div  style="position: absolute;bottom:70px; height:60px; width: 80%;background: white"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer">
      ©2022 中汇数农 苏ICP备 苏B2-20220126
    </div>
  </div>
</template>
<script>
import userLogin from './userlogin'
import changePassword from './changePassword'
import { mapGetters } from 'vuex'
import { dateFormat } from '@/util/date'
import { title, wxOpenKey, wxOpenUrl } from '@/config'
import wxlogin from 'vue-wxlogin'
import { loginByOpen } from '@/api/login'

export default {
  name: 'Login',
  components: {
    changePassword,
    userLogin,
    wxlogin
  },
  data () {
    return {
      time: '',
      // 集火的模式 userNameLogin:用户名登录 mobileCodeLogin:手机号登录 pwd:修改密码
      activeName: 'userNameLogin',
      // 平台标题
      title: title,
      // 选中的登录类型 phone、qrcode、userName
      loginType: 'userName'
    }
  },
  created () {
    if (this.code) {
      loginByOpen(this.code, this.wxOpenKey()).then(res => {
        if (res.success) {
          this.$store.commit('SET_TOKEN', res.data)
          this.$router.push({ path: '/' })
        } else {
          this.$router.push({ path: '/login' })
          this.$message.error(res.msg)
        }
      })
    }
    this.getTime()
    setInterval(() => {
      this.getTime()
    }, 1000)
  },

  computed: {
    ...mapGetters([]),
    // 监听根据集火的属性判断登录的标题
    loginTitle () {
      switch (this.activeName) {
        case 'mobileCodeLogin':
          return '验证码登录'
        case 'pwd':
          return '更改密码'
        case 'userNameLogin':
          return '手机号登录'
        default:
          return ''
      }
    },
    code () {
      return this.$route.query.codes
    }
  },
  props: [],
  methods: {
    wxOpenUrl () {
      return wxOpenUrl
    },
    wxOpenKey () {
      return wxOpenKey
    },
    suc () {
      this.activeName = 'userNameLogin'
    },
    getTime () {
      this.time = dateFormat(new Date())
    },
    changeActiveName (e) {
      this.activeName = e
    },
    check () {
      this.loginType = this.loginType === 'userName' ? 'qrcode' : 'userName'
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/login";
</style>
