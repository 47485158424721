<template>
  <el-form
      class="login-form"
      status-icon
      :rules="loginRules"
      ref="loginForm"
      :model="loginForm"
      label-width="0">
    <el-form-item prop="username" class="login-item" >
      <el-input
          size="small"
          v-model="loginForm.username"
          auto-complete="off"
          :placeholder="loginTitle">
        <i
            slot="prefix"
            class="icon-yonghu"></i>
      </el-input>
    </el-form-item>
    <el-form-item prop="verifyCode" class="login-item" v-if="type==='mobileCodeLogin'">
      <el-input
          size="small"
          v-model="loginForm.verifyCode"
          auto-complete="off"
          placeholder="请输入验证码">
        <i
            slot="prefix"
            class="el-icon-chat-square"></i>
        <template slot="append">
          <span
              @click="handleSend"
              class="msg-text"
              :class="[{display:msgKey}]">{{ msgText }}</span>
        </template>
      </el-input>
    </el-form-item>

    <el-form-item prop="password" class="login-item" v-if="type==='userNameLogin'||showPwd">
      <el-input
          size="small"
          @keyup.enter.native="handleLogin"
          :type="passwordType"
          v-model="loginForm.password"
          auto-complete="off"
          placeholder="密码">
        <i
            class="el-icon-view el-input__icon"
            slot="suffix"
            @click="showPassword"></i>
        <i
            slot="prefix"
            class="el-icon-lock"></i>
      </el-input>
    </el-form-item>
    <div class="login-info">
      <div class="login-forget" @click="forget">
        忘记密码？
      </div>
      <div class="login-forget" @click="switchLoginType">
        {{ loginType }}登录
      </div>
    </div>
    <el-form-item class="login-item">
      <el-button
          type="primary"
          size="small"
          @click.native.prevent="handleLogin"
          class="login-submit">立即登录
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { isValidateMobile, isValidatePassword } from '@/util/validate'
import { sendSms } from '@/api/login'

export default {
  name: 'UserLogin',
  props: {
    activeName: {
      default: '',
      type: String
    }
  },
  data () {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'))
        return
      }
      if (isValidateMobile(value)[0]) {
        callback(new Error(isValidateMobile(value)[1]))
      } else {
        callback()
      }
    }
    const isValidatePwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
        return
      }
      if (!isValidatePassword(value)[0]) {
        callback(new Error(isValidatePassword(value)[1]))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        password: [{ required: true, validator: isValidatePwd, trigger: 'blur' }],
        verifyCode: [{ required: true, validator: validateCode, trigger: 'blur' }]
      },
      passwordType: 'password',
      checked: false,
      type: this.activeName,
      msgText: '',
      msgTime: '',
      msgKey: false,
      loginType: '验证码',
      loginTitle: '请输入手机号',
      isNeedPwd: false,
      showPwd: false
    }
  },
  created () {
    this.msgText = this.config.MSG_INIT
    this.msgTime = this.config.MSG_TIME
  },
  computed: {
    config () {
      return {
        MSG_INIT: '发送验证码',
        MSG_SUCCESS: '秒后重发',
        MSG_TIME: 600
      }
    }
  },
  methods: {
    showPassword () {
      this.passwordType === ''
        ? (this.passwordType = 'password')
        : (this.passwordType = '')
    },
    handleLogin () {
      if (this.type === 'userNameLogin') {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.$store.dispatch('LoginByUsername', this.loginForm).then((res) => {
              this.$store.dispatch('GetUserInfo')
              this.$router.push({ path: '/' })
            }).catch(err => {
              this.$message.error(err.msg || '登录失败')
            })
          }
        })
      } else if (this.type === 'mobileCodeLogin') {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.$set(this.loginForm, 'mobile', this.loginForm.username)
            this.$set(this.loginForm, 'type', 2)
            this.$store.dispatch('LoginByPhone', this.loginForm).then((res) => {
              if (res.success) {
                this.$store.dispatch('GetUserInfo').then(res => {
                  if (res.password) {
                    this.$router.push({ path: '/index' })
                  } else {
                    this.$router.push({ path: '/index', query: { active: true }})
                  }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      }
    },
    forget () {
      this.$emit('activeChanged', 'pwd')
    },
    switchLoginType () {
      this.$refs.loginForm.clearValidate()
      switch (this.type) {
        case 'mobileCodeLogin':
          this.$emit('activeChanged', 'userNameLogin')
          this.type = 'userNameLogin'
          this.loginType = '验证码'
          this.loginTitle = '请输入手机号'
          this.loginForm = {
            username: undefined,
            password: undefined,
            verifyCode: undefined
          }
          break
        case 'userNameLogin':
          this.$emit('activeChanged', 'mobileCodeLogin')
          this.type = 'mobileCodeLogin'
          this.loginType = '手机号'
          this.loginTitle = '请输入手机号'
          this.loginForm = {
            username: undefined,
            password: undefined
          }
          break
        default:
          break
      }
    },
    handleSend () {
      this.$refs.loginForm.validateField('username', err => {
        if (!err) {
          sendSms(this.loginForm.username, this.loginForm.type).then(res => {
            if (res.success) {
              this.msgText = this.msgTime + this.config.MSG_SUCCESS
              this.msgKey = true
              // true-未设置密码 false-已有密码直接登录
              this.isNeedPwd = res.data
              const time = setInterval(() => {
                this.msgTime--
                this.msgText = this.msgTime + this.config.MSG_SUCCESS
                if (this.msgTime === 0) {
                  this.msgTime = this.config.MSG_TIME
                  this.msgText = this.config.MSG_INIT
                  this.msgKey = false
                  clearInterval(time)
                }
              }, 1000)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/login";
</style>
